.time-range {
  grid-template-columns: repeat(14, 1fr);
  margin-top: 3px;
}

@media screen and (min-width: 1025px) and (max-width: 1150px) {
  .time-range {
    grid-template-columns: repeat(7, 1fr);
    *:nth-child(even) {
      display: none;
    }
  }
}

@media screen and (max-width: 780px) {
  .time-range {
    grid-template-columns: repeat(7, 1fr);
    *:nth-child(even) {
      display: none;
    }
  }
}
